import { DiscountTypes, RedeemConditionTypes, ValueTypes } from '@slabcode/kiosks-core/enums';

import { useI18n } from 'vue-i18n';

import { AxiosError } from 'axios';

import { CouponValidation, CouponValidationResponse } from '../interfaces';

export const useCouponStore = defineStore(
  'coupon',
  () => {
    const { storeId, integration } = useKioskInfoStore();
    const languageStore = useLanguageStore();
    const cartStore = useCartStoreV2();
    const orderTypeStore = useOrderTypeStore();

    const promoCode = ref('');
    const loading = ref(false);
    const couponValidation = ref<CouponValidationResponse | null>(null);
    const err = ref('');
    const discount = ref<number>(0);
    const { t } = useI18n();

    // TODO: hacerlo un computed de cartV2
    function itemsCartQuantity() {
      return cartStore.items.reduce((acc, item) => acc + item.item.quantity, 0);
    }

    /**
     * Validate Coupon
     * @param promoCode string
     */
    async function validate() {
      const validationObj: CouponValidation = {
        storeId,
        integrationName: integration,
        promoCode: promoCode.value,
        orderValues: {
          timestamp: new Date().toLocaleString('sv'),
          language: languageStore.currentLanguage.toUpperCase(),
          orderInfo: {
            itemsQuantity: itemsCartQuantity(),
            fulfillmentMode: orderTypeStore.fulfillmentMode,
            orderTotal: {
              subtotal: cartStore.subtotal - cartStore.taxes,
              tax: cartStore.taxes,
            },
          },
        },
      };

      loading.value = true;
      try {
        const res: CouponValidationResponse = (await couponRequests.post('/coupons/validate', validationObj));
        couponValidation.value = res;
        return res;
      } catch (error: unknown) {
        if (error instanceof AxiosError && error.response?.data.message) {
          err.value = error.response?.data.message;
        } else {
          err.value = t('INTERNAL_ERROR');
        }

        return null;
      } finally {
        loading.value = false;
      }
    }

    async function validatePromoCode() {
      const res = await validate();
      if (!res) return;

      if (res.appliedCouponInfo.itemToAddToTheOrder) {
        cartStore.addItemWithoutCustomization(res.appliedCouponInfo.itemToAddToTheOrder.id);
      } else discount.value = res.appliedCouponInfo.discount ?? 0;
    }

    function isCouponValid(): boolean {
      return (
        (couponValidation.value?.reservedCoupon.conditions.type === RedeemConditionTypes.Price
        && cartStore.subtotal + cartStore.taxes >= couponValidation.value?.reservedCoupon.conditions.value)
        || (couponValidation.value?.reservedCoupon.conditions.type === RedeemConditionTypes.Quantity
        && itemsCartQuantity() >= couponValidation.value?.reservedCoupon.conditions.value)
      );
    }

    async function clearCoupon() {
      if (!couponValidation.value) return;
      discount.value = 0;

      try {
        await couponRequests.post('/coupons/cancel', {
          storeId,
          couponId: couponValidation.value?.reservedCoupon.id,
          taskId: couponValidation.value?.reservedCoupon.taskId,
        });
      } catch (error: unknown) {
        if (error instanceof AxiosError && error.response?.data.message) {
          err.value = error.response?.data.message;
        } else {
          err.value = t('INTERNAL_ERROR');
        }
      }

      // if (couponValidation.value?.reservedCoupon.discounts.discountType === DiscountTypes.Product) {
      //   cartStore.removeItem(response.value.appliedCouponInfo.itemToAddToTheOrder);
      // }

      promoCode.value = '';
      loading.value = false;
      couponValidation.value = null;
      err.value = '';
    }

    /**
     * Revalidate Coupon
     *  @param couponId number
     */
    async function revalidateCoupon() {
      if (!couponValidation.value) return;
      if (!isCouponValid()) await clearCoupon();

      if (couponValidation.value?.reservedCoupon.discounts.discountType === DiscountTypes.Invoice) {
        let recalculateDiscount = 0;
        if (couponValidation.value?.reservedCoupon.discounts.valueType === ValueTypes.Price) {
          recalculateDiscount = couponValidation.value?.reservedCoupon.discounts.value ?? 0;
        } else {
          recalculateDiscount = cartStore.subtotal * ((couponValidation.value?.reservedCoupon.discounts.value ?? 0) / 100);
        }
        discount.value = recalculateDiscount;
      }
    }

    function isCouponProduct(itemId: string) {
      return couponValidation.value?.appliedCouponInfo.itemToAddToTheOrder?.id === itemId;
    }

    return {
      loading,
      err,
      discount,
      couponValidation,
      promoCode,
      validatePromoCode,
      revalidateCoupon,
      clearCoupon,
      isCouponProduct,
    };
  },
);
