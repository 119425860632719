<script setup lang="ts">
defineProps({
  modalClass: {
    type: String,
    required: false,
    default: '',
  },
});

const showContent = ref(false);
const kioskImagesStore = useKioskImagesStore();
const { customImages64 } = storeToRefs(kioskImagesStore);

onMounted(() => {
  showContent.value = true;
});
</script>
<template>
  <Teleport to="#modals">
    <div class="modal-background full-hd" />
    <transition name="slide-reverse">
      <div
        v-if="showContent"
        class="fixed inset-0 z-50 flex items-center justify-center kiosk-modal"
      >
        <div class="fixed inset-0 flex flex-col items-center justify-center w-full">
          <div
            :class="[modalClass]"
            class="relative overflow-hidden rounded-cards modal-content"
          >
            <img
              v-if="customImages64?.modalHeader"
              :src="customImages64?.modalHeader"
              rel="prefetch"
              alt="Modal Header"
              class="top-0 modal-image"
            />

            <slot name="default" />

            <img
              v-if="customImages64?.modalFooter"
              :src="customImages64.modalFooter"
              alt="Modal Footer"
              rel="prefetch"
              class="bottom-0 modal-image"
            />
          </div>
          <div
            class="flex items-center justify-center w-full mt-8"
            v-if="$slots['close-button']"
          >
            <slot name="close-button" />
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<style scoped>
.modal-background {
  @apply absolute inset-0 z-40 bg-slate-800 backdrop-blur-sm bg-opacity-35 ;
}

.modal-content {
  @apply w-1/2 bg-white py-28 px-8 flex flex-col items-center justify-start pointer-events-auto;
}

.modal-image {
  @apply h-20 absolute left-1/2 -translate-x-1/2 object-cover object-center;
}
</style>
