<!-- eslint-disable no-console -->
<script lang="ts" setup>
import { PropType } from 'vue';
import { Swiper as Slider, SwiperSlide } from 'swiper/vue';
import { Mousewheel, Navigation } from 'swiper/modules';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { ProductsCategory } from '../interfaces/productsCategory';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const props = defineProps({
  menuItems: {
    type: Array as PropType<ProductsCategory[]>,
    required: true,
  },
});

const emit = defineEmits<{
  goBack: [];
  selectCategory: [category: HubsterCategory];
}>();

const sliderEl = ref();
const menuStore = useMenuStore();
const { loading } = useMenuController();

const kioskImagesStore = useKioskImagesStore();

const { currentCategory } = storeToRefs(menuStore);

watch(
  currentCategory,
  (newValue) => {
    if (sliderEl.value && newValue) {
      const index = props.menuItems.findIndex((c) => c.id === newValue.id);
      const isVisible = sliderEl.value.visibleSlidesIndexes?.includes(index);
      if (!isVisible) sliderEl.value?.slideTo?.(index);
    }
  },
  { deep: true },
);

function goTo(next: boolean) {
  const index = props.menuItems.findIndex((c) => c.id === currentCategory.value?.id);
  const newIndex = next ? index + 1 : index - 1;
  emit('selectCategory', props.menuItems[newIndex]);
}
</script>

<template>
  <nav class="sidebar">
    <button
      type="button"
      class="flex items-center justify-center w-full h-56"
      @click="emit('goBack')"
    >
      <KioskImage
        v-if="kioskImagesStore.customImages64?.brandImage"
        rel="prefetch"
        :src="kioskImagesStore.customImages64.brandImage"
        class="logo"
        image-class="max-h-52"
        hide-border
        alt="logo"
      />
    </button>

    <div class="flex flex-col items-center justify-center slider-menu">
      <button type="button" class="nav-button prev-button-class">
        <span class="text-5xl icon icon-arrow-up" />
      </button>

      <Slider
        v-if="!loading"
        ref="sliderEl"
        :direction="'vertical'"
        :slides-per-view="7"
        :touch-ratio="0.5"
        :autoplay="false"
        :modules="[Navigation, Mousewheel]"
        :navigation="{
          enabled: true,
          prevEl: '.prev-button-class',
          nextEl: '.next-button-class',
        }"
        watch-slides-progress
        touch-start-prevent-default
        class="w-full h-[1568px] border-b"
        @swiper="sliderEl = $event"
        @navigation-prev="goTo(false)"
        @navigation-next="goTo(true)"
      >
        <SwiperSlide
          v-for="item in menuItems"
          :key="item.id"
          class="menu-item-button"
          :class="{ active: currentCategory?.id === item.id }"
          @click="emit('selectCategory', item)"
        >
          <OrderMenuItem :product="item">
            <span
              class="flex items-center mt-2 text-2xl leading-none text-center min-h-12"
            >
              {{ item.name }}
            </span>
          </OrderMenuItem>
        </SwiperSlide>
      </Slider>

      <template v-else>
        <div v-for="n in 8" :key="n" class="flex flex-col items-center w-full h-48 gap-2">
          <div class="w-32 h-32 skeleton" />
          <div class="w-32 h-5 skeleton" />
        </div>
      </template>

      <button type="button" class="nav-button next-button-class">
        <span class="text-5xl icon icon-arrow-down" />
      </button>
    </div>
  </nav>
</template>

<style scoped>
.logo {
  @apply object-cover;
}

.menu-item-button {
  @apply w-full h-full flex flex-col font-light text-neutral-400 items-center justify-center p-3 border-r-4 border-r-transparent border-t border-b-neutral-200;
}

.active {
  @apply bg-kiosk-primary/40 border-r-kiosk-primary border-t-0 text-neutral-600;
}

.top-animation {
  @apply animate-bounce;
}

.sidebar {
  @apply fixed top-0 justify-start items-center bg-white z-20 h-full w-56;
}

.nav-button {
  @apply h-16 disabled:opacity-50;
}
</style>
