<script lang="ts" setup>
import { useStepper } from '@vueuse/core';

const props = defineProps({
  titles: {
    type: Array as PropType<string[]>,
    required: true,
  },
});
defineEmits(['close']);

const { titles } = toRefs(props);
const modalHeight = ref('490px');

const vIndex = defineModel({
  type: Number,
  required: true,
});

const { steps, index, current, goTo } = useStepper(titles.value ?? []);
const transitionName = ref('slide-reverse');

function updateModalHeight() {
  const activeComponent = document.querySelector(
    '.active-modal-step',
  ) as HTMLElement | null;
  if (activeComponent) {
    modalHeight.value = `${activeComponent.scrollHeight}px`;
  }
}

watch(current, async () => {
  await nextTick();
  updateModalHeight();
});

watch(vIndex, (oldVIndex, newVIndex) => {
  if (oldVIndex > newVIndex) transitionName.value = 'slide';
  else transitionName.value = 'slide-reverse';
  setTimeout(() => {
    goTo(steps.value[vIndex.value]);
  }, 300);
});

onBeforeMount(() => {
  vIndex.value = index.value;
});
</script>
<template>
  <KioskModal modal-class="!w-full !max-w-[585px] !px-[52px] !py-[120px]">
    <template #default>
      <p class="pt-2 pb-8 text-3xl text-center" v-if="titles.length > 1">
        {{ $t('STEP') }} {{ index + 1 }}/{{ steps.length }}
      </p>
      <div
        class="relative w-full overflow-hidden transition-all"
        :style="{ minHeight: modalHeight }"
      >
        <transition :name="transitionName" mode="out-in">
          <slot name="default" :key="index" />
        </transition>
      </div>
    </template>
    <template #close-button>
      <slot name="close-button" />
    </template>
  </KioskModal>
</template>
