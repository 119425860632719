<script lang="ts" setup>
import { ItemDescriptor,
  ModifierDescriptor } from '@slabcode/hubster-modifiers-utils';
import { PropType } from 'vue';
import { NestedModifiersLayouts } from '@slabcode/kiosks-core/enums';
import { useItemActionEvent } from '../composables/useItemActionEvent';

const props = defineProps({
  parentItem: {
    type: Object as PropType<ItemDescriptor>,
    required: true,
  },
});

const emit = defineEmits(['cancel', 'save', 'customize']);

const { getModifierClass, pushCustomization, filterItemsWithAlcohol } = useCustomize();
const { selectItem, decreaseItemQuantity, increaseItemQuantity, showMaximumAnimation } = useItemActionEvent();

const changeTrigger = ref(false);

function triggerChange() {
  changeTrigger.value = !changeTrigger.value;
}

function onSelect(item: ItemDescriptor) {
  selectItem(item);
  triggerChange();
}

function onIncrease(item: ItemDescriptor) {
  increaseItemQuantity(item);
  triggerChange();
}

function onDecrease(item: ItemDescriptor) {
  decreaseItemQuantity(item);
  triggerChange();
}

function onCustomize(item: ItemDescriptor) {
  pushCustomization(item);
  triggerChange();
}

const { shouldHideDialog, modifierSelections } = useCustomizeItemStore();

const metadataStoreV2 = useMetadataStoreV2();
const { priceDecimals, brandSettings } = storeToRefs(metadataStoreV2);

const nestedModifiersStyle = computed(() => brandSettings.value?.layout.nestedModifiers);

const isListMode = computed(
  () => nestedModifiersStyle.value === NestedModifiersLayouts.List,
);

const filteredModifiers = computed(() =>
  props.parentItem.modifiers?.filter((m) => {
    if (m.dynamicMaximumSelections === m.dynamicMinimumSelections) return m.items.length >= 1;
    return true;
  }));

function backModifier() {
  emit('cancel');
}

function saveModifierAndUpdateCounter() {
  emit('save');
}

function getModifiersClass(mod: ModifierDescriptor) {
  if (isListMode.value) return 'grid-cols-1 w-full';
  if (mod.items.length === 1) return 'w-full flex justify-center';

  return 'grid-cols-3 gap-4';
}
</script>

<template>
  <KioskModal
    v-if="!shouldHideDialog(parentItem.level)"
    modal-class="!w-4/5"
  >
    <ScrollContainer tw-class="max-h-[1100px]" height="auto" width="100%">
      <div
        v-for="modifier in filteredModifiers"
        :key="modifier.modifierMeta.id"
      >
        <div class="w-full px-5 gap-8 flex justify-between text-[32px]">
          <h1 class="leading-8 tracking-tight">
            {{ modifier.modifierMeta.name }}
          </h1>
          <span
            class="counter-span bg-kiosk-primary"
            :class="{
              'animate__animated animate__headShake': showMaximumAnimation,
            }"
            @animationend="showMaximumAnimation = false"
          >
            {{ modifierSelections(modifier) }}/{{
              modifier.dynamicMaximumSelections
            }}
          </span>
        </div>

        <div
          class="px-4 my-12 items-layout"
          :class="getModifiersClass(modifier)"
        >
          <template
            v-for="item in filterItemsWithAlcohol(modifier.items)"
            :key="item.itemMeta.id"
          >
            <GridItem
              v-if="!isListMode"
              :item="item"
              :container-class="getModifierClass(item, !isListMode)"
              :modifier-decimals="priceDecimals"
              @select="onSelect"
              @customize="onCustomize"
              @add="onIncrease"
              @sub="onDecrease"
              :display-checkbox="
                modifier.dynamicMaxPerModifierSelectionQuantity === 1
                  && modifier.dynamicMaximumSelections > 1
              "
            />
            <ListItem
              v-else
              :item="item"
              :container-class="getModifierClass(item, !isListMode)"
              :modifier-decimals="priceDecimals"
              @select="onSelect"
              @customize="onCustomize"
              @add="onIncrease"
              @sub="onDecrease"
              :display-checkbox="
                modifier.dynamicMaxPerModifierSelectionQuantity === 1
                  && modifier.dynamicMaximumSelections > 1
              "
            />
          </template>
        </div>
      </div>
    </ScrollContainer>

    <div hidden>
      {{ changeTrigger }}
    </div>

    <div class="flex gap-5 mt-5">
      <KioskButton text-size="small" @on-click="backModifier()">
        {{ $t("BACK") }}
      </KioskButton>

      <KioskButton
        color="primary"
        text-size="small"
        :disabled="!parentItem.valid"
        @on-click="saveModifierAndUpdateCounter()"
      >
        {{ $t("SAVE") }}
      </KioskButton>
    </div>
  </KioskModal>
</template>

<style scoped>
.items-layout {
  grid-auto-rows: auto;
  place-items: flex-start;
  @apply grid grid-flow-dense max-w-4xl;
}

.counter-span {
  @apply flex justify-center items-start w-20 h-10 leading-10 text-white border-none rounded px-1 font-bold relative;
}
</style>
