<script lang="ts" setup>
import { PropType } from 'vue';
import { CouponValidationResponse } from '../interfaces';

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  successResponse: {
    type: Object as PropType<CouponValidationResponse>,
    required: true,
    default: () => ({ redeemedCoupons: [] }),
  },
  errorResponse: {
    type: String,
    required: true,
    default: '',
  },
});

const { loading, successResponse, errorResponse } = toRefs(props);

defineEmits(['close']);

const coupon = computed(() => successResponse.value.reservedCoupon);
</script>

<template>
  <KioskModal>
    <div v-if="loading" class="flex items-center justify-center w-full h-auto">
      <img src="/src/assets/images/infty-loader.gif" alt="loading" class="color-black">
    </div>

    <CouponInfo
      v-if="successResponse && successResponse.reservedCoupon && !loading"
      :coupon="coupon"
      @close="$emit('close')"
    />

    <CouponError
      v-if="errorResponse && !loading"
      :error-message="errorResponse"
      @close="$emit('close')"
    />
  </KioskModal>
</template>
