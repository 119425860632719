import { IKioskStatus } from '@slabcode/kiosks-core/interfaces';

export const useKioskStatusStore = defineStore('kioskStatus', () => {
  const errorStore = useErrorStore();
  const kioskInfoStore = useKioskInfoStore();

  const loading = ref(false);
  const pausedBanner = ref<string | null>(null);
  const status = ref(true);
  const updateMetadata = ref(false);

  async function getKioskStatus() {
    loading.value = true;
    try {
      const result: IKioskStatus = await requests.get(`/${kioskInfoStore.storeId}/kioskStatus`);
      pausedBanner.value = result.status ? null : result.pausedBanner!;
      status.value = result.status;
      updateMetadata.value = result.updateMetadata;
    } catch (err) {
      errorStore.handleAndRedirect(err, 'Error getting kiosk status');
      pausedBanner.value = null;
    } finally {
      loading.value = false;
    }
  }

  return { getKioskStatus, status, pausedBanner, loading, updateMetadata };
});
